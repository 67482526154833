<template>
  <div>
    <ribbon class="has-background-white">
      <div class="column is-12 has-text-centered">
        <img
          src="/assets/images/interface/icons/003-checklist.svg"
          width="64"
        />

        <h2 class="is-size-3">Manage your tasks</h2>
      </div>
    </ribbon>

    <ribbon class="has-background-white has-border-top" padding="none">
      <div class="has-padding-x-100">
        <horizontal-nav :routes="routes" :is-borderless="true" />
      </div>
    </ribbon>

    <ribbon class="has-inset-shadow-top">
      <router-view />
    </ribbon>
  </div>
</template>

<script>
export default {
  name: "MyTasks",
  data() {
    return {
      routes: [
        {
          label: "Open tasks",
          to: { path: "/tasks/open" }
        },
        {
          label: "Closed tasks",
          to: { path: "/tasks/closed" }
        }
      ]
    };
  },
  computed: {
    task() {
      return this.$store.getters["tasks/task"](this.$route.params.taskId);
    },
    isTaskCompleted() {
      return this.task && ["complete", "cancelled"].includes(this.task.status);
    }
  }
};
</script>
